<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Assign Picking</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-450px scroll-y mx-5 mx-xl-15 py-7"
      >
        <h4 class="">Selected Pickings</h4>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          class="my-4 pr-8"
          hide-default-footer
          mobile-breakpoint="300"
          :item-class="classSecondText"
        >
        </v-data-table>
        <hr class="my-8" />
        <div class="h-55px mt-5">
          <v-select
            dense
            outlined
            clearable
            multiple
            v-model="formData.pickers"
            label="Select Picker"
            item-text="text"
            item-value="index"
            :items="pickers"
            :error-messages="pickersErrors"
            @blur="$v.formData.pickers.$touch()"
            @input="$v.formData.pickers.$touch()"
          ></v-select>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-center py-7">
          <button
            type="submit"
            class="btn btn--export-filter mr-3 px-5 py-3"
            @click="assignPicker"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
        <!--begin::Body-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { GET_SERVER_DATA } from "@/core/services/store/pickingAssignment.module";

export default {
  name: "AssignPicking",
  mixins: [validationMixin],
  validations: {
    formData: {
      pickers: { required },
    },
  },
  props: {
    refresher: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      pickers: [],
    },
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    classSecondText() {
      return "second-text";
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    assignPicker() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.commit(SET_PAGE_LOADING, true);

        const SELECTED_ORDERS = this.selectedPicking.map(
          (item) => item.order_id
        );
        const data = {
          orders: SELECTED_ORDERS,
          pickers: this.formData.pickers,
        };

        ApiService.post("/warehouse_management/picking_assignment/update", data)
          .then(({ data }) => {
            this.toggleModal();
            this.refresher();

            Swal.fire({
              title: "Completed",
              html: `<div>${data.messages
                .map((mes) => `<div>${mes}</div>`)
                .join("")}</div>`,
              icon: "info",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "OK",
            });
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
  },
  computed: {
    headers: function () {
      const headers = [];
      this.$store.getters.getPICKINGASSIGNMENTTableHeaders.map((item) => {
        if (item.visible) {
          headers.push(item);
        }
      });
      return headers;
    },
    items: function () {
      return this.$store.getters.getPICKINGASSIGNMENTTableSelectedPicking;
    },
    serverData: function () {
      return this.$store.getters.getPICKINGASSIGNMENTTableServerData;
    },
    pickers: function () {
      return this.serverData.pickers;
    },
    selectedPicking: function () {
      return this.$store.getters.getPICKINGASSIGNMENTTableSelectedPicking;
    },
    pickersErrors: function () {
      return this.handleFormValidation("pickers");
    },
  },
  mounted() {
    this.$store.dispatch(GET_SERVER_DATA);
  },
};
</script>
